/* Estilos para a página de Rede Sociais */
.rede-sociais-page {
    background: radial-gradient(circle, #2a960d, white);
    padding: 20px;
    text-align: center;
  }
  
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .social-links {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .social-links li {
    margin: 5px;
  }
  .social-icos {
    width: 120;
    height: 120px;
  }
  .textDark{
    color:#0000;
  }
  .social-links {
    flex-direction: column;
    align-items: center;
  }
  
  /* Estilos para telas menores (por exemplo, dispositivos móveis) */
  @media screen and (max-width: 768px) {
    .social-links {
      flex-direction: column;
      align-items: center;
    }
  }
  