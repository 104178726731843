/* Estilos para a HomePage */
.home-page {
 
    margin: 0;
    text-align: center;
  }
  
  .banner {

    border-radius: 10px;
    margin: 20px auto;
    width: 90%;
  }
  
  .content {
    text-align: center;
    width: 100%;
  }
  .content img{
    width: 95%;
    border-radius: 30px;
    border: 3px solid white;
  }
  .video-text{
    padding-top: 10px;
    padding-bottom: 10px;
  }
 .box-video{
    box-shadow: 5px 2px 8px black;
    height: 100%;
    
 }
 b 
  h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .pcustomhomepage {
    font-size: 18px;
    margin-bottom: 20px;
    color: whitesmoke!important;
  }
  
  .watch-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    background-color: #2a960d;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .watch-button:hover {
    background-color: #1d6f0a;
  }
  
  .video-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center!important;
    align-items: center;
  }
  
  .video-section h2 {
    text-align: center;
    font-size: 24px!important;
  }

  .video-text {
    max-width: 400px!important;
    margin-left: 20px;
  }
  
  
  .column {
    
    max-height: 150px;
    
  }
  .containerpacerios {
    
    align-content: center; /* Centralize verticalmente */
  }
  .separacaocolumn{
    display: inline-block;
    justify-content:center;
  }
  
  .titlehp {
     /* Isso permite que o ícone fique ao lado do texto */
    align-items: center; /* Alinhe verticalmente o ícone */
  }
  
  .iconparcerios {
    width: 50px; /* Defina o tamanho do ícone conforme necessário */
    height: auto; /* Mantenha a proporção do ícone */
    margin-left: 10px; /* Adicione espaço entre o texto e o ícone */
  }
  .containertitle{
    background: #2a960d;
    width: 100%;
    color:white;
  }

  .containertitle h1 {
    font-size:45px!important;
  }
  .titlehp {
    background: #2a960d;
    padding: 10px;
    font-size:2rem!important;
    color: whitesmoke;
  }
  
  
  /* Estilos para telas menores (por exemplo, dispositivos móveis) */
  @media screen and (max-width: 768px) {
    .banner {
      padding: 10px;
    }
  
    
  
    .pcustomhomepage {
      font-size: 16px;
    }
  
    .watch-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  
    .video-section {
      flex-direction: column;
    }
  
    .video-text {
      margin: 20px 0;
    }
    .titlehp h1 {
      font-size:35px!important;
    }
  }

 /* DarkVariantPARC.css */


/* Estilo CSS para o contêiner principal */
.imagensinfinity1 {
  
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 120px; /* Ajuste a altura conforme necessário */
}
.imagensinfinity2 {
  
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 80px; /* Ajuste a altura conforme necessário */
}

/* Estilos para a primeira div dentro de .imagensinfinity */
.imagensinfinity1 .parceiros-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1px; /* Espaçamento entre as duas divs */
}

/* Estilos para a segunda div dentro de .imagensinfinity */
.imagensinfinity2 .casas-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
}



.parceiros-container img,
.casas-container img {
  width: 100%; /* Ajuste a largura das imagens conforme necessário */
  margin: 1px; /* Espaçamento entre as imagens */
}



/* Estilos para as imagens */
.parceiros-container img {
  width: 100px; /* Ajuste a largura das imagens conforme necessário */
  margin-right: 20px; /* Espaçamento entre as imagens */
}


h1 {
  
   margin: 0px!important;
}

.loopimgs {
  margin: 0px 30px!important;
  cursor: pointer;
  font-weight: 700;
  font-size: 7vw;
  color: #2a960d;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #2a960d;
  white-space: nowrap;
  animation: animato 6s linear infinite;
}

.left {
  transform: translate(-50%, 0);
  animation: div_left 10s linear infinite;
}

.right {
  animation: div_right 10s linear infinite;
  
}

@keyframes div_left {                
  25% {
    transform: translate(100%, 0); 
  }        
  50% {
    transform: translate(-50%, 0); 
  }        
  75% {
    transform: translate(0%, 0); 
  }                
  100% {
    transform: translate(-50%, 0); 
  }              
    }

@keyframes div_right {                
  25% {
    transform: translate(-100%, 0); 
  }        
  50% {
    transform: translate(50%, 0); 
  }        
  75% {
    transform: translate(-100%, 0); 
  }                
  100% {
    transform: translate(50%, 0); 
  }
    }

@keyframes animate{
  50%  { color: #2a960d; -webkit-text-stroke: 1px #2a960d;}  
  100%  { color: #fff; -webkit-text-stroke: 1px #2a960d;}  			
}

@keyframes animato{
  50%  { color: #fff; -webkit-text-stroke: 1px #2a960d;}  
  100%  { color: #2a960d; -webkit-text-stroke: 1px #2a960d;}  	
}


/* Estilos para o banner */
.banner {
  border-radius: 10px;
  margin: 20px auto;
  width: 90%;
  
 
  padding: 20px; /* Adicione preenchimento conforme desejado */
}

/* Estilos para o vídeo */
.video-section iframe{
  box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.3);
  width: 640px;
  height: 360px;

  
}



/* Estilos para o texto do vídeo */
.video-text {
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 400px!important;
  margin-left: 20px;
}

/* Estilos para o botão "Assistir" (se houver) */
.watch-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  background-color: #2a960d;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px; /* Adicione margem superior conforme necessário */
}

.watch-button:hover {
  background-color: #1d6f0a;
}

/* Estilos para telas menores (por exemplo, dispositivos móveis) */
@media screen and (max-width: 768px) {
  .banner {
    padding: 10px;
  }

  .video-text {
    margin: 20px 0;
  }

  .watch-button {
    font-size: 16px;
    padding: 8px 16px;
  }
  .box-video{
    width: 100%;
    max-width: 50vw;
    height: 250px;
  }
  .box-video iframe{
    
    max-width: 50vw;
    height: 100%;
  }
  /* Estilos para o vídeo */
.video-section iframe{
  box-shadow: 5px 2px 8px rgba(0, 0, 0, 0.3);
  max-width: 50vw;
  height: 100%;

  
}

}

.custom-container {
  text-align: justify;
  color:white;
}
.cont80{
  width: 89%;
  margin: 0 auto;
  color: white!important;; /* Centraliza a div horizontalmente */
}

.multi-carousel {
  position: relative
}

.multi-carousel .multi-carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0
}

.multi-carousel .multi-carousel-inner .multi-carousel-item {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  padding: 0 3px
}

.multi-carousel .multi-carousel-inner .multi-carousel-item img {
  max-height: 200px;
  -o-object-fit: cover;
  object-fit: cover
}

.multi-carousel.vertical {
  height: 350px
}

@media(max-width: 768px) {
  .multi-carousel.vertical {
      height:150px
  }
  
}

.multi-carousel.vertical .multi-carousel-inner {
  height: 100%
}

.multi-carousel.vertical .multi-carousel-inner .multi-carousel-item {
  padding: 2px 0
}

.multi-carousel.vertical .multi-carousel-inner img {
  max-height: none
}

.multi-carousel.vertical .carousel-control-prev {
  width: 100%;
  height: 15%
}

.multi-carousel.vertical .carousel-control-next {
  top: auto;
  width: 100%;
  height: 15%
}

.multi-carousel.vertical .carousel-control-next span,.multi-carousel.vertical .carousel-control-prev span {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}


.multi-carousel .multi-carousel-inner .multi-carousel-item {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  padding: 0 3px
}

/* Seu arquivo CSS */
.carousel-container-img  img{  
  width: 70%;
}

.carousel-container-img2  img{  
  width: 80%;
}
