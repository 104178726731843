.fs-h1-page-present-extension{
    font-size: 36px;
}


.video-page-present-extension, .container-buttons-page-present-extension{
    width: 615px;
    max-width: 94%;
    margin-left: 3%;
    margin-right: 3%;
}



.video-page-present-extension{
    height: 363px;
    
}

.fs-container-btn-download-page-present-extension *{
    font-size: 24px;
    border-radius: 5px;
}

.icon-browser-page-present-extension{
    width: 46px;
    height: 46px;
}

.btn-download-page-present-extension{
    background-color: #26CE41;
    border: none;
    padding: 10px 7px;
}

.btn-download-page-present-extension:hover{
    background-color: rgb(51, 206, 76);
}

.btn-download-page-present-extension:active{
    background-color: rgb(0, 255, 42);
}

.btn-back-to-bet-page-present-extension{
    width: max-content;
}


@media (max-width: 1400px) {
  
.zete .container-fluid {
    justify-content: space-between !important;
}
.menu-page-present-extension{
    flex-direction: column;
    align-items: center;
}

.menu-links-page-present-extension {
    flex-direction: column;
  }

  .menu-links-page-present-extension .nav-link {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .social-page-present-extension{
    justify-content: center;
    flex-direction: row;    
    align-items: center;
  }
}

@media (max-width: 1400px) {
    .container-buttons-page-present-extension{
        flex-direction: column;
        margin-bottom: 50px;
    }
}

@media (max-width: 655px) {
    .video-page-present-extension{
        height: calc((200/360) * 100vw)!important;
    }
}