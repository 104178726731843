.inframecanva {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}

.sitecanva {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 234.3750%;
  padding-bottom: 0;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  overflow: hidden;
  
  will-change: transform;
}

.caixacontainer {

  background-size: cover;
  /* Cubra todo o elemento pai */
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 100%;
  /* Garanta que a largura seja 100% */
  max-width: 100%;
  /* Garanta que a largura máxima seja 100% */
  text-align: center;
}

.btn-top,
.btn-midle {
  background-color: #007bff;
  color: white;
  padding: 2% 5%;
  /* Use unidades de porcentagem para redimensionar responsivamente */
  border: none;
  cursor: pointer;
  font-size: 2vw;
  /* Use unidades de viewport width (vw) para o tamanho da fonte responsiva */
  position: absolute;
  bottom: 5%;
  /* Posicione os botões a 5% da parte inferior do elemento pai */
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {

  .btn-top,
  .btn-midle {
    font-size: 4vw;
    /* Ajuste o tamanho da fonte para telas menores */
  }
}