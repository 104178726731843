.container-surebet-mobile {
    min-width: 300px;
    width: 100%;
    max-width: 500px;


}

.custom-pt-surebet-mobile {
    padding-top: 0px;
}

.custom-p-x-surebet-mobile {
    padding-left: 20px;
    padding-right: 20px;
}

.custom-pb-surebet-mobile {
    padding-bottom: 20px;
}

.legenda-2-surebet-mobile {
    font-size: 12px !important;
}

.textGray1-surebet-mobile,
.textGray2-surebet-mobile {
    color: #a0a0a0 !important;
}

.legenda-surebet-mobile {
    font-size: 10px !important;
    white-space: nowrap !important;
}

.fs-title-event-surebet-mobile {
    font-size: 18px;
}

.fs-sub-title-event-surebet-mobile {
    font-size: 10px;
}

.name-event-surebet-mobile,
.info-bet-home-surebet-mobile {
    line-height: 1;
}

.info-surebet-mobile {
    display: grid;
}

.icon-interrogation {
    width: 16px !important;
    height: 16px !important;
}

.width-btn-surebet-mobile {
    width: 150px;
}


.bg-btn-access-surebet-mobile {
    background-color: #2A960D !important;
}

.custom-radious-surebet-mobile {
    border-radius: 5px !important;
}

.custom-inner-shadow-btn-surebet-mobile {
    border: none !important;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.9);
}


.custom-text-break-surebet-mobile {

    word-break: break-word !important;
    ;

}

.data-market-surebet-mobile abbr {
    width: 100%;
    font-size: inherit;
    padding: 0 !important;
}

.data-market-surebet-mobile abbr.title {
    font-size: 16px !important;
}

.name-datetime-surebet-mobile {
    max-width: 250px;
}

.custom-py-1-surebet-mobile {
    padding-top: 2px;
    padding-bottom: 2px;
}

.draw-icon-interrogation * {
    fill: #000 !important;
}

.bg-surebet-2{
    background-color: #eceff4;
}

[data-bs-theme=dark-theme] {
    .container-surebet-mobile {
        color: #FFF;
    }

    .draw-icon-interrogation * {
        fill: #FFF !important;
    }

    .bg-surebet-2{
        background-color: #191c20;
    }
}

.logo-star-surebet-mobile {
    height: 15px;
    width: auto;
}

.data-market-0-surebet-mobile span.fs-small {
    font-size: 12px !important;
}

.bg-btn-pause{
    background-color: #019cde!important;
    border-color: #019cde!important;
}

.bg-btn-pause:hover{
    background-color: #217fa8!important;
    border-color: #217fa8!important;
}