/* Estilos para o botão de logout */
.btn-logout {
  background: #290101;
  color: white;
  padding: 10px;
  margin-right: 5px;
  width: 100%;
  right: 10px;
  border-radius: 20px;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn-logout:hover {
  background-color: #ff0000;
  /* Cor de fundo ao passar o mouse */
  color: white;
  /* Cor do texto ao passar o mouse */
}

/* Estilos para os ícones de lua e sol */
.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

/* Estilos para o ícone da lua */
.moon-icon {
  height: 2px;
  margin-top: 0.5em;
  position: absolute;
  top: 27%;
  left: 94.5%;
  /* Ajuste a posição horizontal conforme necessário */
  transform: translate(-50%, -50%);
  z-index: 0;
  /* Coloca o ícone acima da bola */
}

/* Estilos para o ícone do sol */
.sun-icon {
  height: 2px;
  margin-top: 0.5em;
  position: absolute;
  top: 27%;
  left: 96.5%;
  /* Ajuste a posição horizontal conforme necessário */
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Coloca o ícone acima da bola */
}

/* Estilos para a bola */
.form-check-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;

  margin-top: 0.5em;
  position: absolute;
  top: 33%;
  left: 96.5%;
  /* Ajuste a posição horizontal conforme necessário */
  transform: translate(-50%, -50%);
  z-index: 1;
}



/* Outras classes personalizadas */
/* Adicione suas classes personalizadas aqui */

/* Exemplo de classe personalizada */
.custom-header {
  background-color: #2a960d;
  color: white;
  padding: 10px;
}

.social {
  display: flex;
  grid-template-rows: auto auto auto;
  padding: 10px;
  margin-right: 1rem;
  align-items: center;
  justify-items: center;
}

.space-icon {
  padding: 10px;
}

.social-link {
  text-decoration: none;
  display: flex;
  justify-content: left;
  flex-wrap: nowrap;
  align-content: center;
  flex-direction: row;
}

.social-icon {
  height: 35px !important;
  width: 35px !important;

}

.social-circle {
  fill: none;
  stroke: #000;
  /* Cor do traço do círculo */
  stroke-width: 1.5;
  /* Largura do traço do círculo */
}

/* Regras de mídia query para dispositivos móveis */
@media (max-width: 950px) {
  .social {
    /* Altera a direção dos elementos para uma coluna */
    align-items: center;
    justify-content: center;

  }

  .social-link {
    margin-bottom: 5px;
    /* Adiciona um espaço entre os links */
  }
}

@media (max-width: 1200px) {
  .navlink b {
    font-size: 1rem !important;
}
}

.table td, .table th {
  
  border-top: 0px solid #e9ecef;
}

.active{
  background:transparent !important;
}

@media screen and (min-width: 1920px){
a {
    font-size: 16px!important;
}
}

.backblack{
  background-color: rgb(13, 13, 13)!important;
  color: white !important;
  border-top-left-radius:0!important;
  border-top-right-radius:0!important;
}


.btn-close{
  background-color: #ffffff;
  color: rgb(255, 255, 255);
}

thead th {
  font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

@media (max-width: 891px)
{thead th 
  {
    font-size: 1.79574vw!important;
}}