#footer_card{
    display: grid;
    grid-template-columns: auto auto;
    align-content: center;
    width: 100%;
    text-align: center;
    align-items: stretch;
    
    color:white;
    background-color: #343a40!important;
    padding: 20px;
}
.footer-mobile{
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
    margin-top: 12px;
    margin-right: 32vw;
}
.footer-contato-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
@media(max-width:750px){
    #footer_card{
        display: flex;
        flex-direction: column;
    }
}