/* Arquivo LandingPage.css */
.limiterafiliados{
  height: 100vh;
  width: 100%;
}
.containertop {
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
}

.sitecanvacursos{
  position: relative; width: 100%; height: 0; padding-top: 281.2500%;
 padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); overflow: hidden;
 border-radius: 8px; will-change: transform;
}
.inframecanvacursos{
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}
.containermidle {
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.containerbottn {
   width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
}
.content-main {
  
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  
}
iframe .box-video-cursos{
    border: none !important;
    width: 100%;
}
.landing-page {
    width: 100%;
    margin: 0 auto;
    
    height: 100vh;
  }
  .cta-button:hover{
    background:#f49c1a ;
  }
/* Estilos para dispositivos com largura de tela maior que 768px (tablets e desktops) */
@media (min-width: 768px) {
    .landing-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      height: 100%!important;
      width: 100%!important;
      
      
    }
  
    .bannercursos {
      width: 100%;
      padding: 20px;
    }
  
    .contentcursos {
        flex: 1 1;
        display: flex;
        flex-direction: row;
        padding: 20px;
    }
  
    .column {
      margin-bottom: 20px;
    }
  
    .cta-button {
      margin-top: 20px;
      background:#dfe7dd;
      padding: 5px;
      border-radius: 3px;
      width: 150px;
    display: inline-block;
    }
    .box-video-cursos{
        box-shadow: 5px 2px 8px black;
        height: 100%;
        padding: 5px;
        border-radius: 10px;
    }
  }
  
  /* Estilos para dispositivos com largura de tela menor ou igual a 768px (smartphones) */
  @media (max-width: 768px) {
    .landing-page {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  
    .bannercursos {
      padding: 20px;
    }
  
    .contentcursos {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    
  
    .column {
      margin-bottom: 20px;
    }
  
    .cta-button {
        margin-top: 20px;
        background:#dfe7dd;
        padding: 5px;
        border-radius: 3px;
        width: 150px;
    display: inline-block;
      }
     
      .box-video-cursos {
        box-shadow: 5px 2px 8px black;
        height: 100%;
        padding: 5px;
        border-radius: 10px;
    }
    .iframe{
        height: 100%;
    }
  }
 
  