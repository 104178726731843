/* Estilos do cabeçalho */
.header,
thead,
thead tr,
thead th,
.greenArbitragem {
  background-color: #2a960d !important;
}

.navbar {
  background-color: #2a960d !important;
}

.offcanvas {
  background-color: #2a960d !important;

}

.greenArbitragem table {
  border-collapse: collapse !important;
  caption-side: bottom !important;
}

#openAllodds:hover {
  background-color: #0B6F45;
  color: #ECEFF3;
  transition: 0.3s;
}

/* Estilos da tabela */
table tbody:nth-child(odd) {
  background-color: #eceff4 !important;
}

/* Estilos do container de filtro */
.containerFilter {
  width: max-content;
  background: #fff;
  border-radius: 3rem;

}

/* Estilos do texto com abreviação */
.dataMarket abbr {
  text-decoration: none;
  
}

/* Estilos de fundo escuro */
.bgDark {
  background-color: #0d0d0d !important;
}

tbody.bgDark:nth-child(odd) {
  background-color: #191c20 !important;
}

.bgDarkTbodyLinkMobile {
  background-color: #0d0d0d !important;
}

tbody.bgDarkTbodyLinkMobile:nth-child(odd) {
  background-color: #191c20 !important;
}

.textDark,
.textDark2 {
  color: #0d0d0d !important;
}

.textGray,
.textGray-update,
.textGray2,
.textGray1 {
  color: #ced2d6 !important;
}

.textGray-update2 {
  color: #000000 !important;
}

.borderColor {
  border-color: #24282b !important;
}

.calculatorrespon {
  margin: 0 !important;

  font-size: 0.85rem !important;

}

/* Estilos de links */
.colorLink,
.colorLink2,
.colorLink3,
.colorLink4 {
  color: #4ba2f2 !important;
}

.colorLink:hover {
  color: #0084ff !important;
}

.colorLink2:hover {
  color: #000000 !important;
}

/* Estilos de hover cinza */
.hoverGray:hover,
.active.hoverGray {
  background-color: #24282b;
  color: #ced2d6 !important;
}

.active.hoverGray,
.active.hoverGray:hover {
  background-color: #004dc2 !important;
  color: #fff !important;
}

/* Estilos de ícones */
.openAllodds {
  width: 25px;
}

/* Estilos de dispositivos móveis */
.profitMobile,
.profitTrashCalculatorMobile {
  display: none !important;
}

.profitTrashCalculatorMobile {
  padding: 0.25rem 0.50rem;
}
.displayTbodyDesktop{
  display: none;
}


.displayMobile {
  height: 0;
    width: 0;
}

.backgroundProfitMobileLight {
  background-color: #e6e6e6;
}

.backgroundProfitMobileLight2 {
  background-color: #e9e9e9;
}

.backgroundProfitMobileDark {
  background-color: currentColor;
}

.buttonFilterMobile {
  display: none;
}




.curso-pointer{
  cursor: pointer;
}
[data-bs-theme=dark-theme] {
  .classificationActive span{
    background-color: #004dc2!important;    
  }

  .classificationActive span{
    color: #FFFFFF;
  }

  .custom-theme-dropdown{
    background-color: #212529;
  }

  .custom-theme-dropdown span{
    color: #FFFFFF;
  }

  .custom-theme-dropdown span:hover{
    background-color: #2b3035;
  }
}
@media (max-width: 1200px) {
  #filterDesktop{
    display: none;
  }

  .buttonFilterMobile {
    display: block;
  }
}

@media (max-width: 1600px) {
  .fontSizeDefaultDevice2 {
    font-size: 1.10vw !important;
  }

  .title-abbr {
    font-size: 1rem !important;
  }

  .fontSizeDefaultDevice1 {
    font-size: 1.19vw !important;
  }
}

@media (max-width: 891px) {
  .displayMobile {
    transform: scale(1);
  }

  .profitDesktop {
    display: none;
  }

  .profitMobile {
    display: block !important;
  }

  .profitTrashCalculatorMobile {
    display: flex !important;
  }

  .betHome {
    padding-left: 0.50rem !important;
  }

  p {
    margin: 0 !important;
  }

  tbody {
    line-height: 2.5vw !important;
  }

  .profit {
    font-size: 2.24467vw !important;
  }

  .fontSizeDefaultDevice {
    font-size: 1.79574vw !important;
  }

  .defaultSizeBtnTrashCalculatorDevice,
  .iconCalculator {
    height: 3.75vw !important;
    width: 3.75vw !important;
  }

  .defaultSizeBtnTrashCalculatorDevice {
    border-radius: 10% !important;
  }

  .iconTrash {
    height: 3vw !important;
    width: 3vw !important;
  }

  .fontSizeBadgeDefaultDevice {
    font-size: 1.3468vw;
  }


}

@media (max-width: 1200px) {


  .navlink b {
    font-size: 1.29vw !important;
  }

  .openAllodds {
    width: 1.79rem !important;
  }


}



@media (min-width: 768px) {


  .img-footer h2 {

    font-size: 1.2vw !important;
  }

  .fontsizemainbet {
    font-size: 1rem !important;
  }
}

@media (max-width: 1200px) {
  .fontsizemainbet {
    font-size: 1rem !important;
  }
}

@media (max-width: 666px) {
  .fontsizemainbet {
    font-size: 1rem !important;
  }
}

@media (max-width: 561px) {
  .ResponsivHeader {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  .ResponsivHeader img {
    width: 10%;
    height: auto;
  }

  .ResponsivHeader .form-check {
    margin-top: 1em;
  }

  .qt_money .odds .profit {
    text-align: right;
    font-size: 10px !important;
  }
}
.qt_money{
  text-align: end;
  min-width: 40px;
    padding: 6px;}
  #profit_game{
    font-size: 1.79rem!important;
  }
/* Estilos para o botão de logout */
.btn-logout {
  background: #290101;
  color: white;
  padding: 10px;
  margin-right: 5px;
  width: 100%;
  right: 10px;
  border-radius: 20px;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn-logout:hover {
  background-color: #ff0000;
  /* Cor de fundo ao passar o mouse */
  color: white;
  /* Cor do texto ao passar o mouse */
}

/* Estilos para os ícones de lua e sol */
.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

/* Estilos para o ícone da lua */
.moon-icon {
  height: 2px;
  margin-top: 0.5em;
  position: absolute;
  top: 27%;
  left: 94.5%;
  /* Ajuste a posição horizontal conforme necessário */
  transform: translate(-50%, -50%);
  z-index: 0;
  /* Coloca o ícone acima da bola */
}

/* Estilos para o ícone do sol */
.sun-icon {
  height: 2px;
  margin-top: 0.5em;
  position: absolute;
  top: 27%;
  left: 96.5%;
  /* Ajuste a posição horizontal conforme necessário */
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Coloca o ícone acima da bola */
}

/* Estilos para a bola */
.form-check-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;

  margin-top: 0.5em;
  position: absolute;
  top: 33%;
  left: 96.5%;
  /* Ajuste a posição horizontal conforme necessário */
  transform: translate(-50%, -50%);
  z-index: 1;
}


.legenda {
  font-size: 10px !important;
  white-space: nowrap !important;
}

.legenda-2 {
  font-size: 12px !important;
}

.title {
  font-size: 16px !important;
    text-align: center;
    
    display: inline-block;
  
    border-radius: 50px;
    height: auto;
    padding: 10px;
    width: 50%;
}

@media (max-width: 891px) {
  .fontSizeDefaultDevice {
    font-size: 1.79574vw !important;
  }
}

@media (max-width: 891px) {
  tbody {
    line-height: 2.5vw !important;
  }

  .title {
    font-size: 2.15vw !important;
  }

  .legenda-2 {
    font-size: 1.29vw !important;
  }

  .legenda {
    font-size: 1.29vw !important;
    white-space: nowrap !important;
  }
}

.text-title-apostas {
  font-size: 1rem !important;
  font-weight: 700 !important;
  border-bottom: none !important;
}

.text-title-apostas:hover {
  zoom: 1.05;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 666px) {
  .text-title-apostas {
    font-size: 1.79vw !important;
  }
}

.text-Filter {
  font-size: 1rem !important;
}

.chance-main-bet {
  font-size: 1rem!important;
  margin-top: 5px!important;
}

.row {

  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.rowlogin {
  display: contents;
  color: #e4ede1;
  padding: 0;
}

.col {
  padding: 0 !important;

}

tbody {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 10%;
}

@media (min-width: 1920px) {
  .social {
    padding: 20px !important;
  }
}

@media (max-width: 891px) {
  .fontSizeBadgeDefaultDevice {
    font-size: 1.3468vw !important;
  }

 
}

.ps2new {
  padding-left: 3.5rem !important;
}

.table>:not(caption)>*>* {
  background-color: transparent !important;
  border-bottom-width: 0px !important;

}

.table td {
  border-top: 0px solid #e9ecef !important;
}

.table tbody+tbody {
  border-top: 15px solid transparent!important;
}

.table-dark {
  --bs-table-color: #000000 !important;
}

.table-sm>:not(caption)>*>* {
  padding: 0 !important;
}

.table thead th {
  vertical-align: middle !important;

}

.text-faixa-lucro {
  font-size: 0.85rem !important;
}

.backgroundstMobileLight {
  background-color: white !important;
}

.backgroundsMobileLight2 {

  background-color: #eceff4 !important;
}

.tr-dark {
  color: #fff !important;
  background: white !important;
  background-color: white;
}

.textDashDark {
  color: #ffffff !important;

}

.textDashlight {
  color: #000000 !important;

}

.tabledashboard {
  font-size: 1vw !important;

}

.modal-content-vol {
 
  display: block; /* Hidden by default, but visible when triggered */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 50%;
  top: 50%;
  width: 150px; /* Set the width */
  height: auto; /* Adjust the height as needed */
  transform: translate(-50%, -50%); /* Center the modal */
  background-color: white; /* Background color */
  padding: 20px; /* Some padding */
  border: 1px solid #888; /* A border */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Add a shadow for a bit of depth */

}

.containerConfig {
  background: white;
  font-size: 1rem !important;
  border-radius: 6px;
  display: grid;

  align-items: center;
  padding: 20px;
  margin-top: 5%;
  scroll-behavior: smooth;
  align-content: center;
  justify-items: center;
}

.tab-content {
  margin: 10px;
  padding: 1.79vh;
  border: solid 1px #ffffff;
  width: 100%;
  border-radius: 10px !important;
}

.nav .nav-link {
  background: white !important;
  padding: 10px;
  box-shadow: none;
  border-radius: 0px;
}

.form-floating {
  margin: 1rem;

}

.btn-dashboard {
  background: #2a960d;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.forms-configpage {
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.forms-configpage input {
  margin: 1rem;
}

.containerdashboard {
  border: solid 1px #000000;
  width: 100%;
  padding: 10px;
}

.container-pagamentos {

  color: white;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 10px;

}

.container-pagesub {
  padding: 20px;
  width: 100%;
  height: auto;
  border: solid 1px #000000;
  border-radius: 10px;
}

.container-card-pagamentos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}



.card-pagamentos {
  width: calc(25% - 10px);
  /* A largura dos cartões é definida para 25% com margens entre eles */
  margin-bottom: 10px;
  text-align: center;
  background-color: transparent !important;
  margin: 0 0 0 10px;

  transition: transform 0.3s;
}

@media (max-width: 960px) {
  .card-pagamentos {
    width: calc(33.33% - 10px);
    /* Em telas menores, exibir 3 cartões por linha */
  }
}

@media (max-width: 768px) {
  .card-pagamentos {
    width: calc(50% - 10px);
    /* Em telas ainda menores, exibir 2 cartões por linha */
  }
}

@media (max-width: 576px) {
  .card-pagamentos {
    width: 100%;
    /* Em telas muito estreitas, exibir 1 cartão por linha */
  }
}






/*______________________*/



/* Estilo para o título do cartão */
.card-title {
  font-size: 20px;
  font-weight: bold;
}

/* Estilo para o texto do cartão */
.card-text {
  font-size: 1.79vw;
  color: #333;

}






.card-body .card-text {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 12px;
  min-height: 205px;

}

.container-beneficios-pagamento {
  background: transparent !important;


  color: white;
}




@media (max-width: 960px) {

  .titlepagamentos {
    background: #0B6F45;
    color: white;
    padding: 20px;
    border-end-end-radius: 150px;
    border-bottom-left-radius: 150px;
    border-top-left-radius: 190px;
    border-top-right-radius: 190px;
    font-size: 12px !important;
  }

  .card-body button {

    font-size: 1vw;
  }
}

/* Estilo para os cards no hover */
.card-pagamentos:hover {
  transform: scale(1.05);
  /* Aumenta o tamanho do card em 5% */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* Adiciona transições suaves */
}

/* Outros estilos para o card no hover, se necessário */
.card-pagamentos:hover .titlepagamentos {
  background: #0B6F45;
}

.card-pagamentos:hover .titlepagamentos h2 {
  color: white;
}

.card-pagamentos:hover .card-body button {
  background-color: #0056b3;
}

.card-pagamentos:hover .card-text {
  color: #007bff;
}

.profissional-card-pagamentos {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 158.2500%;
  padding-bottom: 0;


  overflow: hidden;
  border-radius: 8px;
  will-change: transform;


}

.iframe-profissional-card-pagamentos {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}

.container-card-pagamentos {
  column-count: 4;
  column-gap: 0;
}

.card-pagamentos-body {
  padding: 0 !important;
}

.input-pagepagmentos {
  margin: 0 !important;
  margin-top: 0.25rem !important;
}

.input-group-text {
  display: block;
}

.borderstyle {
  border: 3.5px solid #2b950d;
}

.borderstyle-s {
  border-left: 3.5px solid #2b950d;

}

.borderstyle-e {
  border-right: 3.5px solid #2b950d;

}

.btn-paginaconfig {
  margin-left: 80% !important;
  padding-bottom: 10px;
}

.container-footer {
  display: contents !important;

  padding: 20px;
}

.footer-page {
  display: grid;
  font-size: 1.79vw !important;
  align-content: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.img-footer img {
  max-width: 100%;
  width: 450px;
}

@media (max-width: 960px) {
  .text-dreservados {
    font-size: 12px !important;
  }

}

@media (min-width: 768px) {

  .img-footer h2 {
    font-size: 1rem !important;
  }
}

/* Estilos CSS para o footer responsivo */

/* Para telas pequenas, como smartphones */
@media (max-width: 575px) {
  .container-footer {
    padding: 10px;
    /* Espaçamento interno reduzido para dispositivos pequenos */
  }

  .img-footer {
    text-align: center;
    /* Centralizar o logotipo e o texto */
    margin-bottom: 20px;
  }

  .text-direitos {
    font-size: 14px;
    /* Tamanho de fonte menor para dispositivos pequenos */
  }

  .footer-page {
    padding: 10px;
    /* Espaçamento interno reduzido para dispositivos pequenos */
  }

  .text-uppercase {
    font-size: 14px;
    /* Tamanho de fonte menor para dispositivos pequenos */
  }
}

.row-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 1.79rem;
  margin-right: auto;
}

.footer-inicio {
  min-width: 200px;
  margin: 0;
}

.footer-politicas {
  min-width: 150px;
  height: auto;
  margin: 0;
}

.footer-contatos {
  min-width: 200px;
  margin: 0;
}

.footer-inicio p {

  margin: 0;
}

.footer-politicas p {

  margin: 0;
}

.footer-contatos p {

  margin: 0;
}

@media (min-width: 1200px) {
  .row-footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;
    padding: 1.79rem;
    margin-right: auto;
  }

  .footer-inicio {
    min-width: 400px;
    margin: 0;
  }

  .footer-politicas {
    min-width: 300px;
    height: auto;
    margin: 0;
  }

  .footer-contatos {
    min-width: 400px;
    margin: 0;
  }

  .title {
    font-size: 1rem !important;
  }
}

@media (max-width: 770px) {
  .row-footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 1.79rem;
    margin-right: auto;
  }

  .footer-inicio p {

    margin-bottom: 5px !important;
  }

  .footer-politicas p {

    margin-bottom: 5px !important;
  }

  .footer-contatos p {

    margin-bottom: 5px !important;
  }

  .img-footer h2 {
    font-size: .5rem !important;
    margin-bottom: 10px !important;
  }
}

@media (min-width: 1920px) {
  p {
    font-size: 16px !important;
  }
}

.text-footer-bottom {
  font-size: .8rem;
}

.p-calculator {
  font-size: 1rem !important;
}

.btn-gp-pagcompra {
  background: #f34e4c;
}

body #containerFindBet {
  margin: 0;
  padding: 0;
  background: #262626;
  height: 600px
}

.ring {
  line-height: 1.5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  height: 380px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #fff000;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #fff000;
  box-shadow: 0 0 20px rgba(0, 0, 0, .5);
}

.ring:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #fff000;
  border-right: 3px solid #fff000;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

#spinnerAnimation {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

#spinnerAnimation:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff000;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #fff000;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

@media (max-width: 450px) {
  .img-listagem {
    width: 10px;
    height: 10px;
  }
  .img-listagem-topo {
    width: 15px;
    height: 15px;
  }
  .img-listagem-voltar {
    width: 5px;
    height: 5px;
  }

}

.modal-termos-codicoes-body{
  text-align: justify;
}

.modal-termos-codicoes-title b{
  font-size: 1.79rem;
  color: #000;
  text-align: center;

}
@media (min-width: 1000px)
{
  .navbar-brands {
    margin-right: -5rem!important;
  }
}

.card_termoscondicoes{
  margin: 0;
  background: white;
  padding: 10px;
}
.card_sobrenos{
  background: white;
}

.alerta-erro-login{
  font-size: 0.85rem!important;
  text-align: center;
}
.alerta-sucess-login{
  font-size: 0.85rem!important;
  text-align: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 80px; /* Aumenta o tamanho da seta */
  width: 80px; /* Aumenta a largura da seta */
  background-image: none; /* Remove a imagem de fundo padrão */
}



.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
  font-size: 40px; /* Aumenta o tamanho da fonte do ícone da seta */
  font-weight: 900;
  color: red; /* Muda a cor do ícone da seta */
}




/* Estilos adicionais para dar mais destaque, como sombra */
.carousel-control-prev,
.carousel-control-next {
  filter: drop-shadow(2px 2px 2px gray); /* Adiciona uma sombra */
}

.button-hidesurebet{
  display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.button-hidesurebet-color{
  padding: 5px!important;
}

.text-sempermissao{
  background: none;
  color: white;
  text-align: center;
  font-size: 1.49rem;
  padding: 20px;
}
.text-sempermissao button{
  font-size: 1.49rem;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
    
  
}

.checkbox-wrapper input[type="checkbox"] {
  margin-left: 140px; /* Adicione margem à esquerda para separar o texto do checkbox */
}
.btn-apostas-home{
  display: flex !important;
  justify-content: end !important;
  align-items: end !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  padding: 15px;

}
.navbar-toggler-icon{
  font-size: 1.75rem;
}

.containerFilter {
  font-size: 1rem!important;
}

.containerFilter .filter span{
  font-size: var(--bs-badge-font-size);
}

.containerFilter .boxOptions .text-Filter{
  font-size: 1rem!important;
}

.title-abbr{
  font-size: var(--bs-badge-font-size)!important;
}
.chance-main-bet{
  font-size: var(--bs-badge-font-size)!important;
}

.openAllodds button{
  font-size: 1.5rem!important;
}

.container-fluid{
  justify-content: flex-start!important;

}
@media screen and (max-width: 1020px) {
  .container-fluid{

    justify-content: space-between!important;


  }

  .btn-apostas-home{
    align-items: center !important;
  }
}
  

.navbar-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 0;
  margin: 0;
}
@media (max-width: 1200px) {
  .navlink b {
      font-size: 1.29vw !important;
  }
 
.form-switch{
  padding: 0!important;
}
}