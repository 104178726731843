/* Removido prefixos não utilizados */


/* Estilos de cartão */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

/* Estilos de cabeçalho */
h5, h6 {
  font-weight: 500;
  line-height: 1.2;
}

/* Estilos de altura 50% */
.h-50 {
  height: 50% !important;
}

/* Sombra */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadowDark {
  box-shadow: 0 0.5rem 1rem rgba(180, 180, 180, 0.15) !important;
}

/* Fundo cinza */
.bg_gray {
  background-color: #f3f3f3;
}

/* Loader */
.loader {
  transform: translateZ(1px);
}

.empty-space-calculator{
  width: 34px;
}

.loader:after {
  content: '$';
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  font-weight: bold;
  background: #ffd700;
  color: #daa520;
  border: 4px double;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes coin-flip {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

/* Alinhamento de texto no centro */
.talign-center {
  height: calc(100vh - 78px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
}

/* Cores de texto */
.text {
  color: #000;
}

/* Cores de loading no calculadora */
.colorGreenLoadingCalculator {
  color: #fff;
}

/* Estilos específicos para calculadora */
.fonte-lucro {
  font-size: 5vw !important;
}

.btn-arrow{
  width: 12px!important;
  height: 14px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0!important;
  padding: 1px!important;
  background-color: #f1f1f1;
  border: none!important;
}

.btn-arrow:hover{
  background-color: #d2d2d2!important;
}


.btn-arrow:active{
  background-color: #787878!important;
}


.btn-arrow:active svg{
  fill: #ffffff!important;
}

.style-fs-micro{
  font-size: 10px;
}
.style-empty-div{
  width: 30px;
}
/* Media query para telas menores que 666px */
@media (max-width: 666px) {
  
  

  label.fontSizeSmall {
    padding-left: 1vw !important;
    padding-right: 2vw !important;
  }

  abbr {
    display: block;
  }

  .fontSizeTitleDataMarketCalculator {
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
    font-size: 1.25rem!important;
  }

  .fontSizeSubTitleEventCalculator {
    font-size: 2.5vw !important;
  }

  .inputCalculator {
    padding: 2vw !important;
    width: 10.511vw !important;
  }

  .columnInfoNameBetHomeDataMarket {
    padding: 0 !important;
  }

  .paddingNameBetHomeMoblie {
    padding: 0 !important;
    padding-left: 1vw !important;
  }

  .fonte-lucro {
    font-size: 5vw !important;
  }
}

.janjan{
  height: 100px!important; 
  overflow-y: scroll!important;
}

.title-event-calculator-mobile{
  font-size: 18px;
}

.sub-title-event-calculator-mobile, .data-market-calculator-mobile{
  font-size: 12px;
}



.info-data-market-calculator-mobile{
  max-height: 1rem;
  max-width: 80%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
        
}

.info-data-market-calculator-mobile abbr{
  text-decoration: none!important;
}

.info-data-market-calculator-mobile .fs-small{
  font-size: 10px!important;
}
.profit-calculator-mobile{
  font-size: 20px;
}

.info-event-mobile{
  padding: 16px;
}

.container-panel-calculator-mobile{
  margin-top: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.box-chance-calculator-mobile{
  min-height: 160px;
  padding-bottom: 14px;
}

.box-stake-calculator-mobile{
  min-height: 120px;
}

.mt-profit-calculator-mobile{
  margin-top: 12px;
}


.fs-home-calculator-mobile{
  font-size: 20px;
}

.mt-data-market-calculator-mobile{
  margin-top: 10px;
}

.form-odd-calculator-mobile{
  margin-top: 16px;
}

.mt-total-value-calculator-mobile{
  margin-top: 28px;
}

.btn-icon-calculator-mobile .icon-plus, .btn-icon-calculator-mobile .icon-less{
  height: 40px;  
  width: 40px;
  cursor: pointer;
}
.btn-icon-calculator-mobile .icon-plus:hover svg path, .btn-icon-calculator-mobile .icon-less:hover svg path{
  fill: #63da42!important;
}

.btn-icon-calculator-mobile .icon-plus:active svg path, .btn-icon-calculator-mobile .icon-less:active svg path{
  fill: #36ac15!important;
}


.btn-icon-calculator-mobile{
  margin-top: 8px;
  
}

.div-input-radio-calculator-mobile{
  width: 25px!important;
  height: 25px!important;
}

.fs-input-radio-calculator-mobile{
  font-size: 25px;
}

.bg-commission-activator{
  background-color: #c0c0c0;
}

.tip-data-market-calculator-mobile{
  width: 20px;
  height: 20px;
}


.icon-interrogation svg{
  width: 20px;
  height: 20px;
}

.break-spaces-calculator{
  white-space: break-spaces;
}

[data-bs-theme=dark-theme] {
  .input-calculator-mobile{
    background-color: #0d0d0d!important;
    color: #FFF;
    border-color: #24282b!important;
  }

  .border-color-dark-mode{
    border-color: #24282b!important;
  }
  .text-color-dark-mode{
    color: #FFF;
  }

  .bg-commission-activator{
    background-color: #191c1f;
  }
  .icon-interrogation svg{
    fill: #FFF;
  }
}