@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

:root {
  --Linear-Gradient: linear-gradient(
    90deg,
    hsl(236, 72%, 79%),
    hsl(237, 63%, 64%)
  );

  --Very-Light-Grayish-Blue: hsl(240, 78%, 98%);
  --Light-Grayish-Blue: #025700;
  --Grayish-Blue: hsl(234, 100%, 53%);
  --Dark-Grayish-Blue: hsl(232, 13%, 33%);
}

main {
  margin: auto;
}

.bg-top {
  position: absolute;
  top: 0;
  right: 0;
  height: 90%;
}

.bg-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40%;
}

#title_part .title h1 {
  font-size: 1.79rem;
  color: var(--Grayish-Blue);
  text-align: center;
}
.btn-pd-mg-new{
  padding: 0!important;
}
#option_part {
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

#option_part h2 {
  font-size: 15px;
  color: var(--Light-Grayish-Blue);
}



.annual_price {
  display: none;
}

#card_part {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  align-content: center;
  width: 100%;
  text-align: center;
  align-items: stretch;
  justify-content: space-between;
}



.cardprofissional {
  background: linear-gradient(to bottom, #025700, #008e00);
  color: white;
  margin-right: 5px;
}

.cardaovivo {
  background: linear-gradient(to bottom, #322456, #574793) !important;
  color: white;
  margin-right: 5px;
}

.cardpro {
  background: linear-gradient(to bottom, #174157, #01848f);
  color: white;
  margin-right: 5px;
}

.cardlight {
  background: linear-gradient(to bottom, #00554a, #018b73);
  color: white;
}

.BTNcardprofissional {
  background: linear-gradient(to bottom, #025700, #008e00);
  color: white;
  margin-right: 5px;
}

.BTNcardaovivo {
  background: linear-gradient(to bottom, #322456, #574793) !important;
  color: white;
  margin-right: 5px;
}

.BTNcardpro {
  background: linear-gradient(to bottom, #174157, #01848f);
  color: white;
  margin-right: 5px;
}

.BTNcardlight {
  background: linear-gradient(to bottom, #00554a, #018b73);
  color: white;
}

.month_price {
  color: #fd2c06;
  font-size: 1.29rem !important;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.9);
}

.h6pricebotton {
  font-size: small;
}

.h6pricetop {
  font-size: medium !important;
  margin-right: 8vw;
}

.card h3 {
  color: white !important;
  font-size: 1.79rem;
  font-weight: 100;
  margin-bottom: 1rem;
}


.card .info {
  margin-top: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: justify;
}

.card .info li {
  list-style-type: none;
  justify-content: left;
  font-size: 0.65rem;
  margin: 0 !important;
  flex-wrap: nowrap;
}

.divprice {
  padding-top: 5rem;
}

.card .info li:not(:nth-child(4)):after {
  content: "";
  position: absolute;
  top: 120%;
  left: 0;

  width: 100%;
  height: 1px;
}

.btnpagecompra {
  margin-top: 1rem;
  border: 1px solid #fff;

  color: white;
  width: 100%;
  font-size: 0.85rem;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.4s linear;
}

.btnpagecompra:hover {
  
  border: 1px solid var(--Dark-Grayish-Blue);
}

.card_2 {
  background: var(--Linear-Gradient);
  color: white;
  min-height: 300px;
}

.card_2 h3 {
  color: white;
}

.card_2 .btnpagecompra {
  color: white;
}

.card_2 .btnpagecompra:hover,.card_1 .btnpagecompra:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}
.card_3 .btnpagecompra:hover,.card_4 .btnpagecompra:hover {
  background: transparent;
  color: #fff;
  border-color: #fff;
}
#footer {
  margin-top: 1.5rem;
  text-align: center;
}

#footer small a {
  text-decoration: none;
  color: var(--Light-Grayish-Blue);
  font-size: 18px;
  transition: 0.2s;
}

#footer small a:hover {
  color: var(--Dark-Grayish-Blue);
}

@media (max-width: 1020px) {
  #card_part {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
  }

  .bg-top {
    height: 50%;
    width: 30%;
  }

  .bg-bottom {
    display: none;
  }

  #footer {
    padding-bottom: 1rem;
  }
}

/*começa aqui*/

.imagempagecompra {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ajuste o estilo da imagem conforme necessário */
}

.botao {
  width: 70%;
  position: absolute;
  bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.5rem;
  left: 50%;
  /* Centralizar o botão horizontalmente */
  transform: translateX(-50%);
  /* Centralizar o botão horizontalmente */
  border: solid 1px black;
  /* Cor de fundo do botão */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  color: #fff;
  /* Cor do texto do botão */
  padding: 10px 20px;
  border-radius: 15px;
  /* Ajuste o preenchimento conforme necessário */
  border: none;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.descontotopright {
  font-size: 1.29rem !important;
  position: absolute;
  right: 5vw;
  top: 0;
  color:red!important;
}
.btn-group > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  
  font-family: 'Roboto', sans-serif;
  background: transparent;
  border-radius: 5px !important;
  border: none;
}

.active-btn {
  background: #008e00 !important;
}

.btn-group {
  background: #145714;
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 5px;
}

@media (max-width: 1020px) {
  .botao {
    width: 70%;
    position: absolute;
    bottom: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-size: 0.85rem;
    left: 50%;
    /* Centralizar o botão horizontalmente */
    transform: translateX(-50%);
    /* Centralizar o botão horizontalmente */
    border: solid 1px black;
    /* Cor de fundo do botão */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    color: #fff;
    /* Cor do texto do botão */
    padding: 10px 20px;
    border-radius: 15px;
    /* Ajuste o preenchimento conforme necessário */
    border: none;
    cursor: pointer;
  }

  .card {
    min-width: 350px;
  }
}

@media (max-width: 720px) {
  #card_part {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
  }

  .card {
    min-width: 350px;
  }
    .descontotopright {
      font-size: 0.5rem !important;
      position: absolute;
      right: -0.5vw;
      top: 0;
      color: red!important;
    }
  }


@media (min-width: 1450px) {
  .botao {
    border: none;
    border-radius: 15px;
    bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    color: #fff;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-size: 4rem;
    left: 50%;
    padding: 10px 20px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 70%;
  }
  .btn-group > .btn {
    background: transparent;
    border: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-radius: 5px !important;
    font-family: 'Roboto', sans-serif;
    font-size: 80px;
    
  }
  .descontotopright {
    font-size: 3.7rem !important;
    position: absolute;
    right: 7.5vw;
    top: 0;
    color:red!important;
  }
  .card {
    min-width: 22vw;
  }
}

@media (min-width: 650px) {
  .descontotopright {
    font-size: 0.7rem !important;
    position: absolute;
    right: 0.1vw;
    top: 0;
    color:red!important;
  }
}
@media (min-width: 750px) {
  .btn-group > .btn {
    
    padding: 0;
  }
  .descontotopright {
    font-size: 0.8rem !important;
    position: absolute;
    right: 3vw;
    top: 0;
    color: red!important;
  }
}
@media (min-width: 850px) {
  .btn-group > .btn {
   
    padding: 0;
  }
  .descontotopright {
    font-size: 0.8rem !important;
    position: absolute;
    right: 2.2vw;
    top: 0;
    color: red!important;
  }
}
@media (min-width: 987px) {
  .btn-group > .btn {
    font-size: 1.5rem!important;
    padding: 0;
  }
  .descontotopright {
    font-size: 0.9rem !important;
    position: absolute;
    right: 5.4vw;
    top: 0;
    color: red!important;
  }
}
@media (min-width: 1020px) {
  .descontotopright {
    font-size: 1.1rem !important;
    position: absolute;
    right: 4.6vw;
    top: 0;
    color: red!important;
  }
  .btn-group > .btn {
    
    padding: 0;
  }
  .botao {
    font-size: 0.80rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-weight: 900;
  }
}
@media (min-width: 1160px) {
  .descontotopright {
    font-size: 0.9rem !important;
    position: absolute;
    right: 4.6vw;
    top: 0;
    color: red!important;
  }
  .btn-group > .btn {
   
    padding: 0;
  }
  .botao {
    font-size: 0.80rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-weight: 900;
  }
}
@media (min-width: 1550px) {
  .btn-group > .btn {
    font-size: 46px;
  }
  .descontotopright {
    font-size: 2rem !important;
    position: absolute;
    right: 3.5vw;
    top: 0;
    color:red!important;
  }

  .botao {
    font-size: 1.29rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  }
}
.btn-planos-pagecompra {
  max-width: 33.33%;
  width: 100%;
  height: auto; 
}
.btn-pd-mg{
  padding: 0!important;
  margin: 0!important;
}

.input-cupon{
  height: auto;
  border: 1px solid;
  border-radius: 30px;
  text-align: center;
  width: 62%;

}

.btn-cupon{
  
    background-color: #145714;
    color: white;
    padding: 5px;
    margin: 7px;
    border-radius: 30px;

}

.mensagem-sucesso{
  background: #aeff71;
  border-radius: 40px;
  color: black;
  font-size: 1rem;
  display: flex;
  border: 2px solid white;
  padding: 8px;
  flex-wrap: wrap;
  /* margin-top: 5px; */
  justify-content: center;
  align-items: baseline;
  align-content: stretch;
}

.mensagem-erro{
  background: #fbff00;
  border-radius: 40px;
  color: black;
  font-size: 1rem;
  display: flex;
  border: 2px solid white;
  padding: 8px;
  flex-wrap: wrap;
  margin-top: 5px;
  justify-content: center;
  align-content: stretch;
  flex-direction: row;
  
  align-items: center;
}
.mensagem-cupon{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.btn-cupon{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-weight: 900;
}

.btn-cupon:hover {
  
  border: 1px solid white;
}

.title-cupon-pagamentos{
  padding-bottom: 0!important;
  font-size: 1.4rem!important;
}

.cupon-page-pagamentos{
  display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
}
.box-pagamentos-mensagem{
  display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: nowrap;
    flex-direction: row;
}
@media screen and (max-width: 1020px) {
  .input-cupon{
    width: 100%;
    height: 30px;
  }
  .cupon-page-pagamentos{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
.plan-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
}

.plan-text {
  font-size: 16px;
  color: #333333;
  margin-right: 10px;
}

.plan-discount {
  background-color: #ff0000;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
}

#monthly-plan .plan-discount {
  background-color: #ff0000;
}

#quarterly-plan .plan-discount {
  background-color: #ff0000;
}

#annual-plan .plan-discount {
  background-color: #ff0000;
}
