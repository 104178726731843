
  
  .card .boxshadow {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px solid red;
    transform: scale(0.8);
    box-shadow: rgb(255, 0, 0) 0px 30px 70px 0px;
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  .card .main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: red;
    background: linear-gradient(0deg, rgb(62, 0, 0) 0%, rgb(255, 0, 0) 60%, rgb(62, 0, 0) 100%);
    position: relative;
    -webkit-clip-path: polygon(0 40px, 40px 0, calc(100% - 40px) 0, 100% 40px, 100% calc(100% - 40px), calc(100% - 40px) 100%, 40px 100%, 0 calc(100% - 40px));
    clip-path: polygon(0 40px, 40px 0, calc(100% - 40px) 0, 100% 40px, 100% calc(100% - 40px), calc(100% - 40px) 100%, 40px 100%, 0 calc(100% - 40px));
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 40px, 100% calc(100% - 40px), calc(100% - 40px) 100%, 40px 100%, 0 calc(100% - 40px));
    clip-path: polygon(0 0, 100% 0, 100% 40px, 100% calc(100% - 40px), calc(100% - 40px) 100%, 40px 100%, 0 calc(100% - 40px));
    box-shadow: rgb(255, 0, 0) 0px 7px 29px 0px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  .card .main .top {
    position: absolute;
    top: 0px;
    left: 0;
    width: 0px;
    height: 0px;
    z-index: 2;
    border-top: 115px solid black;
    border-left: 115px solid transparent;
    border-right: 115px solid transparent;
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  .card .main .side {
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateX(-50%);
    height: 101%;
    background: black;
    -webkit-clip-path: polygon(0% 0%, 50% 0, 95% 45%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 50% 0, 95% 45%, 100% 100%, 0% 100%);
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s;
  }
  
  .card .main .left {
    left: 0;
  }
  
  .card .main .right {
    right: 0;
    transform: translateX(50%) scale(-1, 1);
  }
  
  .card .main .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
    font-weight: bold;
    font-size: 25px;
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-out 0s;
  }
  
  .card .main .button-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .card .main .button-container .button {
    position: absolute;
    transform: translateX(-50%);
    padding: 5px 10px;
    -webkit-clip-path: polygon(0 0, 100% 0, 81% 100%, 21% 100%);
    clip-path: polygon(0 0, 100% 0, 81% 100%, 21% 100%);
    background: black;
    border: none;
    color: white;
    display: grid;
    place-content: center;
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  .card .main .button-container .button .svg {
    width: 15px;
    transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  
  .card .main .button-container .button:nth-child(1) {
    bottom: 300px;
    transition-delay: 0.4s;
  }
  
  .card .main .button-container .button:nth-child(2) {
    bottom: 300px;
    transition-delay: 0.6s;
  }
  
  .card .main .button-container .button:nth-child(3) {
    bottom: 300px;
    transition-delay: 0.8s;
  }
  
  .card .main .button-container .button:hover .svg {
    transform: scale(1.2);
  }
  
  .card .main .button-container .button:active .svg {
    transform: scale(0.7);
  }
  
  .card:hover .main {
    transform: scale(1.1);
  }
  
  .card:hover .main .top {
    top: -50px;
  }
  
  .card:hover .main .left {
    left: -50px;
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;
  }
  
  .card:hover .main .right-config {
    right: -50px;
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;
  }
  
  .card:hover .main .title {
    opacity: 1;
    transition: all 0.2s ease-out 1.3s;
  }
  
  .card:hover .main .button-container .button:nth-child(1) {
    bottom: 80px;
    transition-delay: 0.8s;
  }
  
  .card:hover .main .button-container .button:nth-child(2) {
    bottom: 40px;
    transition-delay: 0.6s;
  }
  
  .card:hover .main .button-container .button:nth-child(3) {
    bottom: 0;
    transition-delay: 0.4s;
  }
  .containerConfig   {
    background: #29910d!important;
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    padding: 20px;
    justify-content: space-around;
    justify-items: stretch;
    color: white;
    border: solid 1px #ffffff;
    
  }
.container-tabs{
    background: #29910d!important;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 20px;
    justify-content: space-around;
    justify-items: stretch;
    color: white;
    
    width: 100%;
}
  .NameTabs li button{
    
    width: 100%;
    text-align: start;
    border-radius: 10px!important;
}

.containerConfig .nav .nav-link {
    background: #29910d!important;
    box-shadow: 0px 3.5px 2px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    color:white
}

/* styles.css */
.custom-button {
    background-color: #29910d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s, box-shadow 0.5s, transform 0.5s;
}

.custom-button:hover {
    background-color: #29910d;
    box-shadow: 20px 20px 20px 30px #29910d;
    transform: translate(-3px, -3px);
}
.title-confipage{
    text-align: center;
}

.config-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  height: 100vh;
  color: white;
  padding-top: 3rem;
  background: #29910d;
}
.config-wrappers {
  display: grid;
  color: white;
  padding-top: 3rem;
  background: #29910d;
  grid-template-columns: auto;
}

.sidebar {
 
  background-color: #29910d; /* Ajuste a cor de fundo conforme necessário */
 padding: 20px;
 font-size: 1.79rem!important;
}
.sidebar .titlesidebar{
 
 
 font-size: 1.59rem!important;
}

.sidebar-item {
  background: #29910d!important;
  box-shadow: 0px 3.5px 2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  color: white;
  margin-bottom: 12px;
  padding: 5px;
  cursor: pointer;
}
.titlesidebar{
  background: #007103!important;
  text-align: center;
  font-family: Roboto;
  padding: 10px;
  background: #007103!important;
    text-align: center;
    font-family: Roboto;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid white;
    /* Defina a borda branca de 1px na parte direita */
border-right: 1px solid white;

/* Defina a borda branca de 1px na parte esquerda */
border-left: 1px solid white;
}
.body-carduser{
  background-color: green;
  padding: 12px;
  border-radius: 14px;
  border: 1px solid white;
  
}
.bodysidebar{
  width: 33%;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-bottom: 1px solid white; */
  /* border-right: 1px solid white; */
  /* border-left: 1px solid white; */
  display: grid;
  grid-template-columns: auto auto auto;
  font-size: 1rem;
}

.bodysidebar-config{
  width: 100%;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;

}

.main-content {
  width: 100%;
  background-color: #29910d; /* Ajuste a cor de fundo conforme necessário */
  padding: 20px;
}

.status-card {
  background-color: green; /* Ajuste as cores conforme o design */
  padding: 20px;
  border-radius: 5px;
}

.status-header {
  font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}


.status-info span {
  font-weight: bold;
}

.status-actions {
  display: flex;
    grid-template-columns: repeat(2, 0.5fr);
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
}

.btn-renovar,
.btn-mudar {
  padding: 4px;
  border: none;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 2px;
  font-size: 1.4rem!important;
}
.btn-renovar{
  margin-right: 1rem;
}
.status-info{
  font-size: 1.4rem!important;
}
.spinner-grow{
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid white;
}
.spinner-grow .text-success{
  background: #31ff00!important;
}
.spuuner-div{
  align-items: center;
}
.btn-renovar {
  background-color: #4CAF50; /* Verde */
}

.btn-mudar {
  background-color: #f44336; /* Vermelho */
}


.container-table  {
  background: #00c917;
    width: 100%;
    padding: 10px;
}

