/* Estilos para o menu horizontal */
/* Defina a variável CSS personalizada */


/* Use a variável no seletor desejado */
.Initial-Control-Page {
  
  width: 100%;
  height: 100%;
  text-align: center;
}

header {
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.4); /* Valores podem ser ajustados conforme necessário */
}
.menu {
  display: flex;
  align-items: center;
}

.menu .search-box {
  margin-right: 20px;
}
.navlin{
  list-style: none;
}
.menu-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu-links .nav-link {
  margin-right: 20px;
}
.nav-link {
  font-size: 14px;
  
}
.nav-link{
  background: #29910d;
  padding: 10px;
  box-shadow: 0px 3.5px 2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  list-style: none;
  text-decoration: none!important; /* Remove o sublinhado */
}
.nav-link-special{
  background: #34ab14;
}

/* Estilos para o menu suspenso */
@media (max-width: 950px) {
  .menu {
    flex-direction: column;
    align-items: center;
  }

  .menu-links {
    flex-direction: column;
  }

  .menu-links .nav-link {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.linknavbar {
  text-decoration: none !important;
  color: white;
  
}

a {
  
  text-decoration: none!important;
}
@media screen and (min-width: 1920px) {
 
  .mudanca-menu {
    margin-right: 30%;
}
  .menuapostas{
    margin-right: 45px;
    height: 60px!important;
    
  }
  .stylefontfull{
    font-size: 23px!important;
  }
  .video-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.video-section h2 {
  font-size: 35px;
  text-align: center;
}

.video-text {
  
  max-width: 600px;

}
}

/* Estilos para telas menores (por exemplo, dispositivos móveis) */
@media screen and (min-width: 2560px) {
  
  header{
    height: 120px!important;
  }
  .imgfullhd{
    height: 75px!important;
    width: auto!important;
}
.social-icon {
  height: 45px!important;
  width: 45px!important;
}
}
.footer-responsivo {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-logo img {
  width: 100%;
}

.footer-group {
  display: flex;
  flex-wrap: wrap;
}

.footer-links ul,
.footer-social ul {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li,
.footer-social li {
  margin-bottom: 10px;
}

.footer-links a,
.footer-social a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}

.title-footer {
  font-weight: bold;
  font-size: 24px!important;
}

.footer-links a:hover,
.footer-social a:hover {
  color: #2a960d; /* Cor de destaque quando o mouse passa por cima */
}


.direitosreservados{
  margin: 0;
}
.footer-social ul {
  display: flex; /* Torna a lista de ícones um flex container */
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-social li {
  margin-right: 10px; /* Espaçamento entre os ícones */
}

.footer-social a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}
.direitosreservados{
  margin-right: 70%;
}

.footer-social{
  margin-right: 40px;
}
.ico-social{
  font-size: 25px;
}
.btn-social{
  text-align: center;
  background:#2a960d ;
  border-radius: 30px;
  padding: 2px;

}

.footer-social {
  display: inline-block;
  justify-content: center; /* Centralizar os elementos horizontalmente */
  align-items: center; /* Centralizar os elementos verticalmente */
  margin-top: 20px; /* Espaçamento superior entre os ícones e o botão */
}

.footer-social ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; /* Tornar a lista de ícones e o botão um flex container */
}

.footer-social li {
  margin-right: 10px; /* Espaçamento entre os ícones e o botão */
}

.footer-social a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}

.btn-social {
  background-color: #2a960d;
  padding: 5px;
  border-radius: 30px;
  display: flex;
  align-items: center; /* Centralizar o texto e o ícone verticalmente */
}

.ico-social {
  margin-right: 5px; /* Espaçamento entre o ícone e o texto no botão */
}
.ico-socialbtn{
  font-size: 15px;
}
@media screen and (max-width: 920px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }
  
}
@media (max-width: 950px){
.social {
    flex-direction: row;
    align-items: center;
}
}

.margin20px{
  margin-right: 20px;
}
.navbar{
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.4);


}

@media (max-width: 950px){
/*.navlink b {
    font-size: 1.79rem!important;
}*/
.imgfullhd{
  height: 38px!important;
  width: auto!important;

}
.titlehp {
  background: #2a960d;
  padding: 10px;
  font-size: 25px!important;
  color: whitesmoke;
}
}

@media (max-width: 270px){
.imgfullhd {
    height: 21px!important;
    width: auto!important;
}
.titlehp {
  background: #2a960d;
  padding: 10px;
  font-size: 15px!important;
  color: whitesmoke;
}
}